window.addEventListener('DOMContentLoaded', function() {
    // Evenement RGPD
    window.initializeMaps = function () {

        function gmap_simple(lat, lng) {
            var map;
            var mapLat = lat;
            var mapLng = lng;
            var mapZoom = 14;
            var myLatLng = {
                lat: mapLat,
                lng: mapLng
            };

            map = new google.maps.Map(document.getElementById("gmap-simple"), {
                mapTypeId: 'roadmap',
                scrollwheel: false,
                panControl: false,
                zoomControl: true,
                zoom: mapZoom,
                center: myLatLng,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false
            });

            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                title: 'Hello World!',
                icon: '/theme/images/icons/gmap-marker.png'
            });
        }

        if ($('#gmap-simple').length) {
            gmap_simple(Number($('#gmap-simple').data('lat')), Number($('#gmap-simple').data('lng')));
        }
    }

});


/*// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyDtPqQNaqulrxB9rgp2DhTFIGzUC1UKZxs';

GoogleMapsLoader.load(function(google) {

  gmap_simple = function(lat, lng) {

    function gmap_simple_init(lat, lng) {
      var map;
      var mapLat = lat;
      var mapLng = lng;
      var mapZoom = 14;
      var myLatLng = {
        lat: mapLat,
        lng: mapLng
      };

      map = new google.maps.Map(document.getElementById("gmap-simple"), {
        mapTypeId: 'roadmap',
        scrollwheel: false,
        panControl: false,
        zoomControl: true,
        zoom: mapZoom,
        center: myLatLng,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: 'Hello World!',
        icon: '/theme/images/icons/gmap-marker.png'
      });
    }

    gmap_simple_init(lat, lng);
  }

  gmap_cluster = function() {

    var markerCluster = require('js-marker-clusterer');

    var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
      zoom: 2,
      center: {
        lat: 31.857457,
        lng: -40.820324
      }
    });

    var infoWin = new google.maps.InfoWindow();
    var markers = locations.map(function(location, i) {
      var marker = new google.maps.Marker({
        position: location
      });
      google.maps.event.addListener(marker, 'click', function(evt) {
        infoWin.setContent(location.info);
        infoWin.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  }

  if ($('#gmap-simple').length) {
    gmap_simple(Number($('#gmap-simple').data('lat')), Number($('#gmap-simple').data('lng')));
  }
  if ($('#gmap-cluster').length) {
    gmap_cluster();
  }

});*/
